interface InactivityScreenController {
  getInactivityScreen: () => Promise<boolean>
}

const InactivityScreenService: InactivityScreenController = {
  getInactivityScreen: async () => {
    try {
      const response = await fetch(process.env.NEXT_PUBLIC_STRAPI_API || '', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          operationName: 'Hometopup',
          variables: {},
          query: `
        query Hometopup {
          hometopup {
            data {
              attributes {
                inactivityScreen
              }
            }
          }
        }
      `,
        }),
      })
      const data = await response.json()
      return data?.data.hometopup.data.attributes.inactivityScreen
    } catch (error) {
      //
    }
  },
}

export { InactivityScreenService }
